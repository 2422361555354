<template>
  <q-card class="ferry-result-card" :class="selected ? 'selected bg-primary-tint' : null">
    <img src="~assets/northlink-logo.png" class="logo">
    <div>
      <strong><sup>Depart</sup><br>
        {{ result.departure_time.date | timeFilter }}</strong> {{ result.route.origin.name }}
    </div>
    <div>
      <strong><sup>Arrive</sup><br>
        {{ result.arrival_time.date | timeFilter }}</strong> {{ result.route.destination.name }}
    </div>
    <q-separator style="grid-area: separator" />
    <div class="price">
      {{ $n(result.total.amount/100, 'currency') }}
    </div>
  </q-card>
</template>

<script>
import date from 'utils/date-time'

export default {
  filters: {
    timeFilter (val) {
      return date.toCivilTime(val)
    }
  },
  props: ['result', 'selected']
}
</script>

<style lang="stylus" scoped>
.ferry-result-card
  display grid
  grid-template-columns 100px auto
  grid-row-gap 5px
  grid-template-areas \
  "logo depart-time-origin" \
  "logo arrival-time-destination" \
  "separator separator" \
  ". from" \
  "price price"
  width 90vw
  max-width 600px
  margin-top 20px
  padding 15px
  color black
  border-radius 5px
  line-height 1
  word-spacing 0.5em

.selected
  animation click 0.1s

.logo
  grid-area logo
  padding-right 15px
  margin 5px 0

.price
  grid-area price
  font-size 125%
  font-weight 500
  line-height 90%

@keyframes click
  0%
    transform scale(1, 1)
  50%
    transform scale(0.95, 0.95)
  100%
    transform scale(1, 1)
</style>
