<template>
  <q-page v-if="summary">
    <!-- Route -->
    <q-card>
      <q-card-section v-if="$q.platform.is.desktop" class="bg-primary">
        {{ $tc('route') }}
      </q-card-section>
      <q-card-section>
        <h6 class="text-uppercase">
          {{ summary.outbound_route_name }}
        </h6>
        <div class="route-time">
          <strong>{{ $t('ferry.outbound') }}</strong>
          <div>{{ summary.outbound_departure_time.date | date }}</div>
        </div>
        <div v-if="summary.return_route_name" class="route-time">
          <strong>{{ $t('ferry.return') }}</strong>
          <div>{{ summary.return_departure_time.date | date }}</div>
        </div>
      </q-card-section>
    </q-card>
    <!-- Tickets -->
    <q-card>
      <q-card-section class="bg-primary">
        {{ $tc('tickets.ticket', 2) }}
      </q-card-section>
      <q-card-section>
        <h6 class="text-uppercase">
          {{ $t('ferry.outbound') }}
        </h6>
        <div
          v-for="(type, key) in summary.price_breakdown.passengers.outbound"
          :key="key"
          class="tickets-summary"
        >
          <div class="type-number text-capitalize">
            <strong>{{ $tc(`num.${key}`, type.quantity) }}</strong>
          </div>
          <div class="type-total">
            {{ type.total_price.text }}
          </div>
          <q-separator class="type-separator" />
        </div>
      </q-card-section>
      <q-card-section v-if="summary.price_breakdown.passengers.return">
        <h6 class="text-uppercase">
          {{ $t('ferry.return') }}
        </h6>
        <div
          v-for="(type, key) in summary.price_breakdown.passengers.return"
          :key="key"
          class="tickets-summary"
        >
          <div class="type-number text-capitalize">
            <strong>{{ $tc(`num.${key}`, type.quantity) }}</strong>
          </div>
          <div class="type-total">
            {{ type.total_price.text }}
          </div>
          <q-separator class="type-separator" />
        </div>
      </q-card-section>
    </q-card>
    <!-- Price -->
    <q-card>
      <q-card-section class="bg-primary">
        {{ $t('price') }}
      </q-card-section>
      <q-card-section class="price-summary">
        <strong>{{ $t('booking.fee') }}</strong> <div class="text-right">
          {{ summary.price_breakdown.mobilleo_booking_fee.text }}
        </div>
        <q-separator class="price-separator" />
        <strong>{{ $t('tax.vat') }}</strong> <div class="text-right">
          {{ summary.price_breakdown.vat.text }}
        </div>
        <q-separator class="price-separator" />
        <strong>{{ $t('total_cost') }}</strong> <div class="text-right">
          {{ summary.total_price.text }}
        </div>
        <q-separator class="price-separator" />
      </q-card-section>
    </q-card>
    <!-- Terms -->
    <q-card>
      <q-card-section class="bg-primary">
        {{ $t('terms_and_conditions') }}
      </q-card-section>
      <q-card-section class="tnc">
        <i18n path="ferry.northlink.dangerous_goods.text">
          <a href="https://www.northlinkferries.co.uk/booking-info/prohibited-items/" target="_blank">{{ $t('ferry.northlink.dangerous_goods.link') }}</a>
        </i18n>
        <label>
          <input v-model="acceptance.dangerous_goods" type="checkbox">
          {{ $t('ferry.northlink.dangerous_goods.accept') }}
        </label>
        <i18n path="ferry.northlink.terms.text">
          <a href="https://www.northlinkferries.co.uk/legal/terms-and-conditions/" target="_blank">{{ $t('ferry.northlink.terms.link') }}</a>
        </i18n>
        <label>
          <input v-model="acceptance.terms_and_conditions" type="checkbox">
          {{ $t('ferry.northlink.terms.accept') }}
        </label>
      </q-card-section>
    </q-card>
    <m-attach-to-booking-request
      class="width-600"
      :suggested="summary.outbound_route_name"
      :username="stash.params.user.value"
      :journey="journey"
      :show-travel-type="organisation && organisation.attributes.travel_policy.ferry && organisation.attributes.travel_policy.ferry.enabled"
      @change="(val) => $store.dispatch('ondemand/journey', val)"
      @reasonChange="(val) => reason_for = val"
      @labelChange="(val) => labels = val"
      @travelTypeChange="(val) => travelType = val"
    />
    <payment-buttons
      class="width-600"
      :total-amount="summary.total_price.amount"
      :requester="stash.params.requester"
      :journey="journey"
      :reason-for="reason_for"
      :labels="labels"
      :booking-token="summary.booking_token"
      :travel-type="(organisation && organisation.attributes.travel_policy.ferry && organisation.attributes.travel_policy.ferry.enabled) || !organisation ? travelType : 'Private'"
      :acceptance="acceptance"
      :disable="!acceptance.terms_and_conditions || !acceptance.dangerous_goods"
    />
  </q-page>
</template>

<script>
import store from 'store'
import i18n from 'i18n'
import { summary } from 'api/ferry'
import loading from 'utils/loading'
import { mapGetters } from 'vuex'
import date from 'utils/date-time'
import { MAttachToBookingRequest } from 'components/'
import paymentButtons from 'pages/payments/payment-method-buttons'
import { handleErrors } from 'utils/utils'

export default {
  components: {
    MAttachToBookingRequest,
    paymentButtons
  },
  filters: {
    date (val) {
      return date.toMediumDateTime(val)
    }
  },
  data () {
    return {
      summary: null,
      reason_for: null,
      labels: null,
      travelType: null,
      acceptance: {
        dangerous_goods: false,
        terms_and_conditions: false
      }
    }
  },
  computed: {
    ...mapGetters({
      journey: 'ondemand/journey',
      stash: 'ondemand/stash',
      organisation: 'organisation'
    })
  },
  async beforeRouteEnter (to, from, next) {
    const { results, passengers } = store.getters['ondemand/stash']
    const partner = store.getters.partner

    loading.start({
      message: i18n.t('loading.submitting_details'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      const { data } = await summary(results.search_token, { passengers })

      next(vm => {
        vm.summary = data
      })
    } catch (err) {
      handleErrors(err)
      next(false)
    } finally {
      loading.stop()
    }
  }
}
</script>

<style lang="stylus" scoped>
.q-page
  background white
  display flex
  flex-direction column
  align-items center

.q-card
  width 100%
  max-width 600px
  @media (max-width: 768px)
    margin 0

.width-600
  width 100%
  max-width 616px

h6
  margin-bottom 15px

.route-time
  display flex
  flex-direction row
  justify-content space-between
  margin-top 10px

.tickets-summary
  display grid
  grid-template-columns auto 60px
  grid-template-rows 1fr 10px
  grid-row-gap 10px
  grid-template-areas "type-number type-total" \ "type-separator type-separator"

.type-price
  text-align right

.type-number
  grid-area type-number

.type-total
  grid-area type-total
  text-align right

.type-separator
  grid-area type-separator

.price-summary
  display grid
  grid-template-columns auto 60px
  grid-template-rows 1fr 5px 1fr 5px 1fr 5px
  grid-row-gap 10px

.price-separator
  grid-column 1 / span 2

.tnc
  font-size 85%
  color rgba(0, 0, 0, 0.5)
  display flex
  flex-direction column
  label
    margin 5px 0
    display flex
    align-items flex-start
    position relative

a
  color convert(brandColor)
  font-weight 500

input[type="checkbox"]
  margin 5px
  appearance none
  border 1px solid
  padding 5px
  border-radius 2px
  &:checked
    padding 0 0.5px
    line-height 10px
    &:after
      content '\2714'
</style>
