<template>
  <q-page v-if="results" class="results-list">
    <h5 class="text-capitalize">
      {{ leg }} journey
    </h5>
    <h6>{{ results[leg][0].departure_time.date | dateFilter }}</h6>
    <ferry-result-card
      v-for="(result, index) in results[leg]"
      :key="'result' + index"
      :result="result"
      :selected="result.service_token === selected"
      @click.native="() => setSelected(result.service_token)"
    />

    <button class="continue-button" @click="nextLeg">
      {{ $t('continue') }}
    </button>
  </q-page>
</template>

<script>
import store from 'store'
import date from 'utils/date-time'
import loading from 'utils/loading'
import i18n from 'i18n'
import { search } from 'api/ferry'
import ferryResultCard from './ferry-result-card'
import { handleErrors } from 'utils/utils'

export default {
  filters: {
    dateFilter (val) {
      return date.toMediumDate(val)
    }
  },
  components: { ferryResultCard },
  data () {
    return {
      leg: 'outbound'
    }
  },
  computed: {
    selected: {
      get () {
        return this.leg === 'return'
          ? this.$store.getters['ondemand/stash'].return_service_token
          : this.$store.getters['ondemand/stash'].outbound_service_token
      },
      set (service_token) {
        this.leg === 'return'
          ? this.$store.dispatch('ondemand/stash', { return_service_token: service_token })
          : this.$store.dispatch('ondemand/stash', { outbound_service_token: service_token })
      }
    },
    results () {
      return this.$store.getters['ondemand/stash'].results
    }
  },
  async beforeRouteEnter (to, from, next) {
    loading.start({
      message: i18n.t('loading.checking.ferry'),
      partnerSlug: store.getters.partner.slug,
      spinnerColor: store.getters.partner.hex
    })
    try {
      const { params } = await store.getters['ondemand/stash']

      const mapParams = {
        ...params,
        user: params.user.value,
        requester: params.requester.value
      }

      const { data } = await search(mapParams)
      next(() => {
        store.dispatch('ondemand/stash', { results: data })
      })
    } catch (err) {
      handleErrors(err)
      next(false)
    } finally {
      loading.stop()
    }
  },
  methods: {
    setSelected (service_token) {
      this.selected = service_token
    },
    nextLeg () {
      if (!this.selected) return
      if (this.results.return.length && this.leg === 'outbound') {
        this.leg = 'return'
        return
      }
      this.$router.push({ name: 'ondemand-ferry-passengers' })
    }
  }
}
</script>

<style lang="stylus" scoped>
.results-list
  padding-top 15px
  display flex
  flex-direction column
  align-items center
  position relative
  background white

.continue-button
  position absolute
  bottom 15px
  right 5%
  left 5%
  z-index 100
  width 90%
  max-width 600px
  border none
  background convert(brandColor)
  color white
  text-transform uppercase
  font-weight 500
  padding 10px
  border-radius 5px
  @media (min-width 768px)
    left calc(50% - 300px)
    right calc(50% - 300px)
</style>
