import { render, staticRenderFns } from "./passenger-details.vue?vue&type=template&id=539914a4&scoped=true&"
import script from "./passenger-details.vue?vue&type=script&lang=js&"
export * from "./passenger-details.vue?vue&type=script&lang=js&"
import style0 from "./passenger-details.vue?vue&type=style&index=0&id=539914a4&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "539914a4",
  null
  
)

export default component.exports