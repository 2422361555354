<template>
  <form>
    <q-card v-for="(passenger, key) in passengers" :key="key" class="passenger-form" square>
      <q-card-section class="bg-primary">
        {{ key === 'P1' ? 'Main passenger' : `Passenger ${key.slice(1)}` }}
      </q-card-section>
      <label>
        {{ $t('title') }}
        <input v-model="passenger.title" class="title">
      </label>
      <i18n v-if="$v.passengers[key].title.$error" path="validation.please_provide.default" class="error">
        <span>{{ $t('validation.please_provide.title') }}</span>{{ $t('passenger_x', {n: key.slice(1)}) }}
      </i18n>

      <label>
        {{ $t('name.forename') }}
        <input v-model="passenger.first_name" class="first-name">
      </label>
      <i18n v-if="$v.passengers[key].first_name.$error" path="validation.please_provide.default" class="error">
        <span>{{ $t('validation.please_provide.first_name') }}</span>{{ $t('passenger_x', {n: key.slice(1)}) }}
      </i18n>

      <label>
        {{ $t('name.surname') }}
        <input v-model="passenger.last_name" class="last-name">
      </label>
      <i18n v-if="$v.passengers[key].last_name.$error" path="validation.please_provide.default" class="error">
        <span>{{ $t('validation.please_provide.last_name') }}</span>{{ $t('passenger_x', {n: key.slice(1)}) }}
      </i18n>

      <!-- Details for main passenger only -->
      <label v-if="key === 'P1'">
        {{ $t('phone') }}
        <div class="phone-label">
          <q-select
            v-model="dialling_code"
            :display-value="`+${dialling_code}`"
            :options="callingCodeSelect"
            dense
            filter
            emit-value
            map-options
            hide-dropdown-icon
            :borderless="$q.platform.is.mobile"
          />
          <input v-model="passengers.P1.mobile_phone_number" class="phone">
        </div>
      </label>
      <i18n v-if="$v.passengers.P1.mobile_phone_dialcode.$error" path="validation.please_provide.default" class="error">
        <span>{{ $t('validation.please_provide.dial_code') }}</span>{{ $t('passenger_x', {n: key.slice(1)}) }}
      </i18n>
      <i18n v-if="$v.passengers.P1.mobile_phone_number.$error" path="validation.please_provide.default" class="error">
        <span>{{ $t('validation.please_provide.phone') }}</span>{{ $t('passenger_x', {n: key.slice(1)}) }}
      </i18n>

      <label v-if="key === 'P1'">
        {{ $t('email') }}
        <input v-model="passenger.email" class="email">
      </label>
      <i18n v-if="$v.passengers.P1.email.$error" path="validation.please_provide.default" class="error">
        <span>{{ $t('validation.please_provide.email') }}</span>{{ $t('passenger_x', {n: key.slice(1)}) }}
      </i18n>
    </q-card>
    <div class="submit-footer">
      <button class="submit-button" @click.prevent="submit">
        {{ $t('submit') }}
      </button>
    </div>
  </form>
</template>

<script>
import loading from 'utils/loading'
import store from 'store'
import i18n from 'i18n'
import { Notify } from 'quasar'
import { handleErrors } from 'utils/utils'
import { passengers as getPassengers, accommodation } from 'api/ferry'
import { required } from 'vuelidate/lib/validators'
import worldCountries from 'world-countries/dist/countries.json'
import _ from 'lodash'

export default {
  data () {
    return {
      mobile_phone_dial_code: '44',
      mobile_phone_number: null,
      passengers: { }
    }
  },
  computed: {
    callingCodeSelect () {
      const countryData = _.orderBy(worldCountries, 'name.common')
      const callingCodeSelect = []

      countryData.forEach((c) => {
        if (c.idd.root) {
          c.idd.suffixes.forEach(code => {
            const trimmedCode = code.replace(/X/g, '')
            callingCodeSelect.push({
              label: `${c.idd.root}${trimmedCode} - ${c.name.common}`,
              value: c.idd.root.slice(1) + trimmedCode
            })
          })
        }
      })

      return callingCodeSelect
    },
    dialling_code: {
      get () {
        return this.passengers.P1.mobile_phone_dialcode || null
      },
      set (val) {
        this.passengers.P1.mobile_phone_dialcode = val
      }
    }
  },
  async beforeRouteEnter (to, from, next) {
    loading.start({
      message: i18n.t('loading.checking.your_details'),
      partnerSlug: store.getters.partner.slug,
      spinnerColor: store.getters.partner.hex
    })

    const { results, return_service_token, outbound_service_token } = store.getters['ondemand/stash']
    const params = { return_service_token, outbound_service_token }

    try {
      await accommodation(results.search_token, params)
      const { data } = await getPassengers(results.search_token, { accommodations: { outbound: [], return: [] } })
      next(vm => {
        vm.passengers = data.required_details.passengers
        if (!data.required_details.passengers.P1.mobile_phone_dialcode) {
          vm.passengers.P1.mobile_phone_dialcode = '44'
        }
      })
    } catch (err) {
      if (err.errors) {
        if (Object.keys(err.errors).includes('search_token')) {
          Notify.create({ message: i18n.t('error.search_token_expired') })
          next({ name: 'ondemand-ferry' })
        }
      } else {
        handleErrors(err)
        next(false)
      }
    } finally {
      loading.stop()
    }
  },
  validations () {
    const validations = {
      passengers: {}
    }
    for (const key in this.passengers) {
      validations.passengers[key] = {
        title: { required },
        first_name: { required },
        last_name: { required }
      }
    }

    validations.passengers.P1 = {
      ...validations.passengers.P1,
      email: { required },
      mobile_phone_number: { required },
      mobile_phone_dialcode: { required }
    }

    return validations
  },
  methods: {
    submit () {
      this.$v.$touch()
      if (this.$v.$error) return
      this.$store.dispatch('ondemand/stash', { passengers: this.passengers })
      this.$router.push({ name: 'ondemand-ferry-summary' })
    }
  }
}
</script>

<style lang="stylus" scoped>
form
  background white
  display flex
  flex-flow column
  height calc(100vh - 64px)
  @media (min-width: 768px)
    flex-flow row wrap
    justify-content space-around

.passenger-form
  display flex
  flex-direction column
  background white

.q-card
  @media (max-width: 768px)
    margin 8px 0
    box-shadow none

label
  display flex
  flex-direction column
  padding 8px 16px
  margin 10px 10px 0 10px
  border solid rgba(0,0,0,0.2) 1px
  border-radius 25px
  font-size 75%
  letter-spacing 0.00937em
  @media (min-width: 768px)
    border none
    input
      border-bottom solid rgba(0,0,0,0.2) 1px
  &.q-select
    font-size 16px
    margin 0 5px 0 0
    padding 0
    border none

.phone-label
  display flex
  flex-direction row

select
  border none
  background none
  font-size 16px
  margin-left -3px

@keyframes shake {
  0% {
    transform translateX(0)
  }
  25% {
    transform translateX(-2px)
  }
  50% {
    transform translateX(0)
  }
  75% {
    transform translateX(2px)
  }
  100% {
    transform translateX(0)
  }
}

.error
  color red
  padding 0 16px
  font-size 80%
  animation shake 0.1s 2

.submit-footer
  width 95%
  margin 2.5%
  display flex
  justify-content center

.submit-button
  width 100%
  max-width 600px
  color white
  background-color convert(brandColor)
  border none
  text-transform uppercase
  font-weight 500
  border-radius 10px
  padding 8px 16px
  align-self flex-end
</style>
